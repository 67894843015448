const HeaderData = [
  {
    title: "HOME",
    link: "/",
  },
  {
    title: "HISTORY",
    link: "/history",
  },
  {
    title: "ARTWORK",
    link: "/artwork",
  },
  {
    title: "ALBUM AND MEANING",
    link: "/album",
  },
  {
    title: "BOOK AN APPOINTMENT",
    link: "/booking",
  },
];

export default HeaderData;
