import Header from "./Header";
import Footer from "./Footer";

const PageWrapper = ({ children, wrapperClassName, wrapperStyles }) => {
  return (
    <div
      className={`flex flex-col overflow-x-hidden pl-[330px] md:pl-0 !bg-transparent ${wrapperClassName}`}
      style={wrapperStyles}
    >
      <Header />
      <div className="sm:pt-0 bg-gree-300">{children}</div>
      <Footer />
    </div>
  );
};

export default PageWrapper;
