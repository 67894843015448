import PageWrapper from "../components/basic/PageWrapper";
import LandingSection from "../components/components/HomePage/LandingSection";
import HomePageHelmet from "../components/components/HomePage/HomePageHelmet";

const HomePage = () => {
  return (
    <>
      <HomePageHelmet />
      <PageWrapper>
        <LandingSection />
      </PageWrapper>
    </>
  );
};

export default HomePage;
