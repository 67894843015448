import { Container, Image } from "react-bootstrap";

const albumImageData = [
  { src: "/assets/img/album-page/album-1.jpg" },
  { src: "/assets/img/album-page/album-2.jpg" },
  { src: "/assets/img/album-page/album-3.jpg" },
  { src: "/assets/img/album-page/album-4.jpg" },
  { src: "/assets/img/album-page/album-5.jpg" },
  { src: "/assets/img/album-page/album-6.jpg" },
  { src: "/assets/img/album-page/album-7.jpg" },
  { src: "/assets/img/album-page/album-8.jpg" },
  { src: "/assets/img/album-page/album-9.jpg" },
  { src: "/assets/img/album-page/album-10.jpg" },
  { src: "/assets/img/album-page/album-11.jpg" },
  { src: "/assets/img/album-page/album-12.jpg" },
  { src: "/assets/img/album-page/album-13.jpg" },
  { src: "/assets/img/album-page/album-14.jpg" },
  { src: "/assets/img/album-page/album-15.jpg" },
  { src: "/assets/img/album-page/album-16.jpg" },
  { src: "/assets/img/album-page/album-17.jpg" },
  { src: "/assets/img/album-page/album-18.jpg" },
  { src: "/assets/img/album-page/album-19.jpg" },
  { src: "/assets/img/album-page/album-20.jpg" },
  { src: "/assets/img/album-page/album-21.jpg" },
  { src: "/assets/img/album-page/album-22.jpg" },
  { src: "/assets/img/album-page/album-23.jpg" },
  { src: "/assets/img/album-page/album-24.jpg" },
  { src: "/assets/img/album-page/album-25.jpg" },
  { src: "/assets/img/album-page/album-26.jpg" },
  { src: "/assets/img/album-page/album-27.jpg" },
  { src: "/assets/img/album-page/album-28.jpg" },
  { src: "/assets/img/album-page/album-29.jpg" },
  { src: "/assets/img/album-page/album-30.jpg" },
  { src: "/assets/img/album-page/album-31.jpg" },
  { src: "/assets/img/album-page/album-32.jpg" },
  { src: "/assets/img/album-page/album-33.jpg" },
  { src: "/assets/img/album-page/album-34.jpg" },
  { src: "/assets/img/album-page/album-35.jpg" },
  { src: "/assets/img/album-page/album-36.jpg" },
  { src: "/assets/img/album-page/album-37.jpg" },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className="my-20">
        <div className="text-[22px] leading-9 text-[#c5c5c5]">
          <div className="text-white font-bold below-bottom">
            <h3 className="!mb-0">ALBUM AND MEANING</h3>
          </div>
        </div>

        <div className="flex flex-col items-center gap-12 mt-12">
          {albumImageData.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              alt="album"
              className="object-cover flex"
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
