import { Helmet } from "react-helmet-async";

const HomePageHelmet = () => {
  return (
		<Helmet>
			<title>Bangkok Sak Yant - World</title>
			<meta
				name='description'
				content='Explore the ancient art of Sak Yant tattoos in Bangkok. Discover spiritual designs, sacred traditions, and the profound meanings behind these unique tattoos.'
			/>
			<meta
				name='keywords'
				content='Sak Yant tattoos, Bangkok tattoos, sacred tattoos, spiritual tattoos, Thai traditions, Bangkok Sak Yant'
			/>
			<meta property='og:type' content='website' />
			<meta property='og:url' content='https://bangkoksakyant.com' />
			<meta
				property='og:title'
				content='Bangkok Sak Yant - Sacred Tattoos & Spiritual Art'
			/>
			<meta
				property='og:image'
				content='https://bangkoksakyant.com/assets/img/bangkok-logo.png'
			/>
			<meta
				property='og:description'
				content='Discover the sacred tradition of Sak Yant tattoos in Bangkok. Spiritual designs crafted with care and rich cultural meaning.'
			/>
			<meta property='og:site_name' content='Bangkok Sak Yant' />
			<meta property='og:locale' content='en_US' />
			<link rel='canonical' href='https://bangkoksakyant.com' />
		</Helmet>
	);
};

export default HomePageHelmet;
