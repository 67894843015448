import { Col, Container, Image, Row } from "react-bootstrap";
import { Link as ScrollTo } from "react-scroll";
import Buttons from "../../../Litho/Components/Button/Buttons";
import SwipperSection from "./SwipperSection";

const LandingSection = () => {
  return (
    <div className="overflow-hidden relative">
      <SwipperSection />

      <Container className="relative space-y-12 mt-12">
        <div className="text-[20px] leading-9 text-[#aaaaaa] sm:text-[16px]">
          <div className="text-white font-bold below-bottom">
            <h5 className="!mb-0">What is Sakyant?</h5>
          </div>
          <div>
            Sakyant is an invitation to the Triple Gem, namely the Buddha, the
            Dharma and the Sangha into us.
            <br />
            <br />
            1) Buddha being the highest above all, in and on our head.
            <br />
            <br />
            2) Inviting the parents. To come to the left and right of our
            shoulders, for them to protect and take care of us because there is
            no best blessing besides parents.
            <br />
            <br />
            3) Invitation of the teachers, who performed the ceremony in the
            middle of the heart. As when, you do something, think of the teacher
            first, which will come to help you for things that you’re are doing,
            making such things smoother and making you successful in your
            endeavors.
            <br />
            <br />
            4) The invitation of Yantras, characters, spells, and magic (Wicha)
            to cover the whole body. To protect and to heal the body from all
            harm, danger and inauspicious calamities which we might meet each
            day. Safety from all dangers, to be merciful and be loved by all.
            The above are just some examples, which in general, people seek for,
            however, the sakyant can also be tailored to your specific needs if
            required.
            <br />
            <br />
            It can be seen that after receiving the Sakyant, you must remember
            the four things mentioned above. Always remember to keep praying,
            meditating and dedicating merits to teachers, that has passed away,
            in which this magical sakyant has been passed down from. The owner
            of the sakyant, has to keep these dear to his heart and his mind
            from the past to the present, so that this magical sakyant, will
            help bless you. As remembering and paying homage to teachers and
            masters are the most important aspect in any faith.
            <br />
            <br />
            And if always remembered, whenever you require any assistance or
            advice, the teachers and masters will be there for you.
            <br />
            <br />
            However, after mentioning the above, one must also be mindful, that
            this ancient art of sakyant is done in the right way, and that the
            master whom you seek does indeed possess such knowledge and wicha in
            him, not just anyone whom copy and pastes sakyant pictures and only
            knows how to do a sakyant tattoo. As all sakyants, must be activated
            by a certain spell and a certain wicha, knowing how to tattoo and
            copying just a similar sakyant picture and getting anyone to ink it
            on your body, would not only not help you, but bring you down.
            <br />
            <br />
            Also always note that, not remembering the teachers, not praying to
            monks, meditating and not having faith, it’s like hurting yourself
            without any benefit.
          </div>
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-2.jpg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-3.jpeg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>

        <div className="text-[20px] leading-9 text-[#aaaaaa] sm:text-[16px]">
          <div className="text-white font-bold below-bottom">
            <h5 className="!mb-0">Sakyant</h5>
          </div>
          <div>
            It’s a belief and faith that has been with Thailand for a long time.
            Every Thai person knows Sakyant. Because this is one of the ancient
            cultures of Thailand. That we all love and have faith in since the
            ancient times, these subjects were one of the subjects of protecting
            the country and was recorded in an ancient scripture called (phi
            chai song kram พิชัยสงคราม ) which will be recorded in a type of
            book made from the bark of the Khoi tree (book koy สมุดข่อย ) which
            will record stories of organizing troops to fight and calculating
            the stars to see good and bad days. Astrology House building subject
            look at the directions that make residents happy, healing subjects,
            magic spells, yantra.
            <br /> <br />
            Learning about the past makes us understand that Sakyant means
            understanding nature, the four elements of earth, water, fire, wind
            and harnessing the energy of nature. To drive the energy that arises
            from the meditation practice of the magic student and bring it to be
            charged and inscribed in the yantra. This creates priceless energy.
            This is just a small part. of intelligence of the ancestors who
            jointly recorded Book of magic Because memorization cannot make the
            yantra generate energy Students must know how to concentrate. calm
            your mind Do it correctly. to elevate the spirit of learners and
            leads to the highest meaning of life is emptiness (nirvana).
          </div>
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-4.jpeg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className="text-[20px] leading-9 text-[#aaaaaa] sm:text-[16px]">
          <div className="text-white font-bold below-bottom">
            <h5 className="!mb-0">Exclusive for you!!!</h5>
          </div>
          <div>
            Come join us for an amazing experience with Bangkok Traditional
            Sakyant done by Master Tor. He would like to invite you to
            experience the ancient culture of Thailand that will make you even
            want to know how special it is. Therefore, You will get to know the
            ancient science of sakyantra and the original sakyantra method of
            Thailand done by Master Tor, he is the 4th generation inheritor of
            the ancient technique and the inheritor of the ancient technique
            that has been around for more than 200 years. In Year of Thailand,
            You will feel strength and Magical energy that you have never
            experienced before with this ancient magic that will make your trip
            unforgettable.
          </div>
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-5.jpeg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className="text-[20px] leading-9 text-[#aaaaaa] sm:text-[16px]">
          <div className="text-white font-bold below-bottom">
            <h5 className="!mb-0">
              Uncover the Ancient Secrets of a 200-Year-Old Book of Magic!!!
            </h5>
          </div>
          <div>
            <br />
            Passed down through generations, this sacred script holds the key to
            unlock mystical powers. Which embark on a journey of wonder and
            discovery.
            <br />
            <br />
            book magic, a sacred book that records magic (katha) (yantra) that
            Master Tor has inherited from his ancestors, masters and currently
            he is the keeper of more than 200 books of book magic of masters,
            and he is considered to be a master (karawas) who has One of the
            most magical scriptures in Thailand. A traditional old school real
            Sakyant was pass down from the old generation Grandmaster with an
            old school script which have all the basic Sakyant Design as a
            template and Our Sakyant Master Tor who can modifies Sakyant meaning
            by changing the spell to suit the individual specific wishes and
            desires by the luskit. This is why you can not get an Authentic
            Sakyant from any of the Tattoo artist; who can not read the magical
            script. <br />
            <br />
            As all Master has different of script to be learn and also all
            Master came from different lineage. The old school traditional
            script includes of catching of spirit, magical healing from sickness
            and also all the katta which Master Tor had learn was from old
            school traditional script.
            <br />
            <br /> Common practice which Master will do is to understand your
            need to have sakyant in order to help you and from there he will
            choose for your the Sakyant which suit you.
          </div>
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-7.jpeg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-8.jpeg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className=" ">
          <Image
            src="/assets/img/home-page/main-9.jpg"
            alt="gold"
            className="object-cover w-full h-full flex"
          />
        </div>
        <div className="text-[20px] leading-9 text-[#aaaaaa] sm:text-[16px]">
          <div className="text-white font-bold below-bottom">
            <h5 className="!mb-0">Master Arjan Tor</h5>
          </div>
          <div>
            <br />
            All Sakyant, given by Arjan Tor at his Samnak, are all traditional
            and follow the olden scriptures and lana wicha being passed down
            since 200 years ago.
            <br />
            <br />
            No form of modified sakyants or any other sakyants, self designed or
            modified otherwise, not in the teachings of Arjan Tor, will be
            accepted in Arjan Tor’s samnak.
            <br />
            <br />
            Arjan Tor, prides himself in his knowledge of sakynant and wicha,
            which was traditionally passed down and learnt through all his
            Masters, in the traditional way and ancient forms.
            <br />
            <br />
            You should consult personally with Arjan Tor, on the issues that you
            might be facing in life or what aspects of life you would like to
            focus on and improve.
            <br />
            Arjan Tor, would then discuss a specific or even if required a
            specially customised sakyant to suit your requirements, in order for
            you to achieve the best results in life after getting the sakyant.
            <br />
            <br />
            Arjan Tor, prides himself in helping all whom seek him, and making
            sure whatever sakyant being inked onto your body would be
            efficacious, and help you in your life, be it in your work, family,
            or even spiritually.
            <br />
            <br />
            Arjan Tor, also prides himself in learning everything and doing
            everything in the traditional ways which has been passed down over
            to him for generations upon generations from famous masters, wanting
            all his sakyants wicha, when placed on the body of those seeking
            them to help, guide them and increase aspects of their life.
            <br />
            <br />
          </div>
        </div>
        <div className="flex justify-between sxl:flex-col sxl:justify-center sxl:items-center sxl:gap-12">
          <div className="w-50">
            <div className="text-white font-bold below-bottom">
              <h5 className="!mb-0">Scan to Contact us on WhatsApp</h5>
            </div>
            <div className="mt-8">
              <Image
                src="/assets/img/home-page/whatsapp-code.jpg"
                alt="gold"
                className="object-cover w-[465px] h-full flex"
              />
            </div>
          </div>
          <div className="w-50">
            <div className="text-white font-bold below-bottom">
              <h5 className="!mb-0">Scan to Contact us on Instagram</h5>
            </div>
            <div className="mt-8">
              <Image
                src="/assets/img/home-page/insta-code.jpg"
                alt="gold"
                className="object-cover w-[450px] h-full flex"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LandingSection;
