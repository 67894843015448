import PageWrapper from "../components/basic/PageWrapper";
import MainContainer from "../components/components/Album/MainContainer";

const AlbumPage = () => {
	return (
		<PageWrapper>
			<MainContainer />
		</PageWrapper>
	);
};

export default AlbumPage;
