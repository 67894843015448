import React, { useState } from "react";

// Libraries
import { Container, Image } from "react-bootstrap";

const artImageData = [
  {
    src: "/assets/img/art-page/art-1.jpg",
  },
  {
    src: "/assets/img/art-page/art-2.png",
  },
  {
    src: "/assets/img/art-page/art-3.jpg",
  },
  {
    src: "/assets/img/art-page/art-4.png",
  },
  {
    src: "/assets/img/art-page/art-5.png",
  },
  {
    src: "/assets/img/art-page/art-6.jpg",
  },
  {
    src: "/assets/img/art-page/art-7.jpg",
  },
  {
    src: "/assets/img/art-page/art-8.jpg",
  },
  {
    src: "/assets/img/art-page/art-9.png",
  },
  {
    src: "/assets/img/art-page/art-10.png",
  },
  {
    src: "/assets/img/art-page/art-11.jpg",
  },
  {
    src: "/assets/img/art-page/art-12.png",
  },
  {
    src: "/assets/img/art-page/art-13.png",
  },
  {
    src: "/assets/img/art-page/art-14.jpg",
  },
  {
    src: "/assets/img/art-page/art-15.jpg",
  },
  {
    src: "/assets/img/art-page/art-16.jpg",
  },
  {
    src: "/assets/img/art-page/art-17.jpg",
  },
  {
    src: "/assets/img/art-page/art-18.jpg",
  },
  {
    src: "/assets/img/art-page/art-19.jpg",
  },
];

const MainContainer = (props) => {
  return (
    <div>
      <Container className="mt-20">
        <div className="text-[22px] leading-9 text-[#c5c5c5]">
          <div className="text-white font-bold below-bottom">
            <h3 className="!mb-0">ARTWORK</h3>
          </div>
        </div>

        <div className="flex flex-col items-center gap-12 mt-12">
          {artImageData.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              alt="artwork"
              className="object-cover flex"
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
