import PageWrapper from "../components/basic/PageWrapper";
import MainContainer from "../components/components/Art/MainContainer";

const ArtPage = () => {
  return (
		<PageWrapper>
			<MainContainer />
		</PageWrapper>
	);
};

export default ArtPage;
