import { Col, Container, Row } from 'react-bootstrap';
import PortfolioColorful from '../../../Litho/Components/Portfolio/PortfolioColorful';

const portfolioColorfulData = [
	{
		img: '/assets/img/history-page/history-1.jpeg',
		double_col: true,
	},
	{
		img: '/assets/img/history-page/history-2.jpeg',
	},
	{
		img: '/assets/img/history-page/history-3.jpeg',
	},
	{
		img: '/assets/img/history-page/history-4.jpeg',
		double_col: true,
	},
	{
		img: '/assets/img/history-page/history-5.jpeg',
	},
	{
		img: '/assets/img/history-page/history-6-variant.jpg',
	},
];

const HistoryCollage = () => {
	return (
		<section>
			<Container fluid className='px-0'>
				<Row>
					<Col>
						<PortfolioColorful
							className='m-0'
							overlay='#d96f41e6'
							grid='grid grid-4col xl-grid-4col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col text-center'
							data={portfolioColorfulData}
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default HistoryCollage;
