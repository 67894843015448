import PageWrapper from '../components/basic/PageWrapper';
import BookingForm from '../components/components/BookingPage/BookingForm';

const BookingPage = () => {
	return (
		<PageWrapper>
			<BookingForm />
		</PageWrapper>
	);
};

export default BookingPage;
