import { useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Keyboard, Pagination } from 'swiper/modules';
import { m } from 'framer-motion';
import MouseMove from '../../../Litho/Components/MouseMove';

const SwiperImgData = [
	{
		img: '/assets/img/history-page/history-7.jpeg',
		title: 'HISTORY',
	},
];

const HistoryHeader = () => {
	const swiperRef = useRef(null);
	const [activeSlide, setActiveSlide] = useState(0);

	return (
		<section className='relative'>
			<Swiper
				modules={[Autoplay, EffectFade, Keyboard, Pagination]}
				effect='fade'
				className='relative swiper-pagination-fractional photography-slider'
				loop={true}
				ref={swiperRef}
				pagination={true}
				keyboard={{ enabled: true, onlyInViewport: true }}
				fadeEffect={{ crossFade: true }}
				slidesPerView={1}
				onSlideChange={(swiperCore) => {
					const { realIndex } = swiperCore;
					setActiveSlide(realIndex);
				}}
			>
				{SwiperImgData.map((item, i) => {
					return (
						<SwiperSlide
							key={i}
							className='relative full-screen flex flex-col items-center justify-center font-serif bg-cover bg-center xs:h-[664px]'
							style={{ backgroundImage: `url(${item.img})` }}
						>
							<Swiper
								modules={[Autoplay]}
								autoplay={{ delay: 0, disableOnInteraction: false }}
								speed={15000}
								loop={true}
								allowTouchMove={false}
								className='absolute top-1/2 w-full -translate-y-1/2 text-[235px] leading-[235px] tracking-[-12px] font-bold uppercase opacity-10 z-0 swiper-auto-slide lg:text-[150px] sm:text-[100px] xs:leading-[60px] xs:text-[80px]'
							>
								<SwiperSlide>{item.title}</SwiperSlide>
								<SwiperSlide>{item.title}</SwiperSlide>
								<SwiperSlide>{item.title}</SwiperSlide>
								<SwiperSlide>{item.title}</SwiperSlide>
							</Swiper>
							<Row className='h-full'>
								<Col className='h-full justify-center items-center flex flex-col'>
									<m.div
										initial={{ opacity: 0, y: 30 }}
										animate={
											activeSlide === i
												? { opacity: 1, y: 0 }
												: { opacity: 0, y: 0 }
										}
										transition={{ delay: 0.5, duration: 0.6 }}
										className='relative overflow-hidden font-serif text-center text-[235px] xl:text-[210px] lg:text-[150px] lg:leading-[150px] md:text-[120px] sm:text-[110px] xs:text-[76px] xs:leading-[76px] leading-none font-bold uppercase landscape:md:text-[57px] landscape:md:leading-[57px]'
									>
										<div className='relative z-[5] flex justify-center lg:inline-block text-gradient bg-gradient-to-r from-[#fac467] via-[#f7d18d] to-[#f6a26f]'>
											{item.title}
										</div>
										<MouseMove
											enableOnHover={true}
											speed={2}
											className='absolute top-0 text-gradient bg-transparent'
										>
											<span className='inline-block text-border text-border-width-2px text-[#f7d18d]'>
												{item.title}
											</span>
										</MouseMove>
									</m.div>

									<m.div
										className='absolute bottom-[80px] inline-block'
										initial={{
											clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
										}}
										animate={{
											clipPath:
												activeSlide === i
													? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
													: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
										}}
										transition={{ delay: 2, duration: 0.6 }}
									>
										<Link
											aria-label='link for'
											to={item.btnLink}
											className='text-[#828282] hover:text-white text-lg inline-block text-decoration-line-bottom-medium font-serif font-normal xs:text-xmd'
										>
											{item.btnName}
										</Link>
									</m.div>
								</Col>
							</Row>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</section>
	);
};

export default HistoryHeader;
