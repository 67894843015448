import { Col, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Header as LithoHeader,
  CollapsibleMenu,
  HamburgerMenu,
  HeaderNav,
} from "../../Litho/Components/Header/Header";
import ReactCustomScrollbar from "../../Litho/Components/ReactCustomScrollbar";

const Header = ({ props }) => {
  return (
    <div>
      <LithoHeader
        topSpace={{ md: true }}
        type="pos-left"
        className="w-[330px] h-[100vh] z-10 md:w-full md:h-auto"
      >
        <div className="flex  flex-col h-full bg-[#101010] border-r border-[#ffffff1a] pt-10 overflow-hidden md:pt-40 md:hidden">
          <Col xs="auto" className="text-center leading-none logo-div">
            <Link
              aria-label="header logo"
              to="/"
              className="inline-block leading-none"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="240"
                  height="240"
                  loading="lazy"
                  src="/assets/img/bangkok-logo.png"
                  data-rjs="/assets/img/bangkok-logo.png"
                  alt="logo"
                />
                {/* <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-copper-red-black.webp"
                  data-rjs="/assets/img/webp/logo-copper-red-black.webp"
                  alt="logo"
                /> */}
              </Navbar.Brand>
            </Link>
          </Col>
          <Col xs="auto" className="max-h-[calc(100%-160px)]">
            <ReactCustomScrollbar
              className="!items-start"
              autoHide
              theme="light"
            >
              <div>
                <CollapsibleMenu
                  className="left-sidebar-menu w-full"
                  theme="light"
                />
              </div>
            </ReactCustomScrollbar>
          </Col>
        </div>

        {/* Mobile menu start */}
        <HeaderNav
          className="hidden md:flex justify-between px-[30px] sm:pl-[15px] md:py-[15px]"
          bg="black"
          theme="dark"
          containerClass="px-0"
        >
          <Link className="flex items-center justify-center" to="/">
            <Navbar.Brand className="inline-block p-0 m-0">
              <img
                src="/assets/img/bangkok-logo.png"
                className="w-[36px] "
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
          <HamburgerMenu
            theme="dark"
            position="left"
            className="w-[330px] flex flex-col justify-end !top-[var(--header-height)]"
            closeBtn={false}
          >
            <div
              className="bg-[#101010] px-8 md:px-0 pb-20"
              style={{ height: `calc(100vh - var(--header-height))` }}
            >
              <ReactCustomScrollbar
                autoHide
                className="pl-[15px] md:pl-0 mt-[50px] !items-start"
              >
                <div>
                  <CollapsibleMenu
                    className="w-full left-sidebar-menu"
                    theme="light"
                  />
                </div>
              </ReactCustomScrollbar>
            </div>
          </HamburgerMenu>
        </HeaderNav>
        {/* Mobile menu end */}
      </LithoHeader>
    </div>
  );
};

export default Header;
