import PageWrapper from '../components/basic/PageWrapper';
import MainContainer from '../components/components/History/MainContainer';

const HistoryPage = () => {
	return (
		<PageWrapper>
			<MainContainer />
		</PageWrapper>
	);
};

export default HistoryPage;
